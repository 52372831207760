export class CreditsService {
  constructor() {
    this.client = useSupabaseClient();
  }

  /**
   * Fetch all credits for the current user
   * @returns {Promise<{ data: Array, error: Error }>}
   */
  async fetchCredits() {
    return await this.client.from('credits').select('*').order('created_at', {ascending: false});
  }

  /**
   * Get customer portal URL from Paddle
   * @returns {Promise<{ data: { url: string }, error: Error }>}
   */
  async getCustomerPortalUrl() {
    return await this.client.functions.invoke('get-customer-portal-url');
  }

  /**
   * Update subscription
   * @param {string} subscription_id
   * @param {string} price_id
   * @returns {Promise<{ data: any, error: Error }>}
   */
  async updateSubscription(subscription_id, price_id) {
    return await this.client.functions.invoke('update-subscription', {
      body: {subscription_id, price_id}
    });
  }

  /**
   * Cancel subscription
   * @returns {Promise<{ data: any, error: Error }>}
   */
  async cancelSubscription() {
    return await this.client.functions.invoke('cancel-subscription');
  }

  /**
   * Calculate total available credits
   * @param {Array} credits
   * @returns {number}
   */
  calculateAvailableCredits(credits) {
    return credits.reduce((total, credit) => {
      if (credit.valid_until && new Date(credit.valid_until) < new Date()) {
        return total; // Skip expired credits
      }
      return total + credit.remaining_credits;
    }, 0);
  }

  /**
   * Calculate total credits
   * @param {Array} credits
   * @returns {number}
   */
  calculateTotalCredits(credits) {
    return credits.reduce((sum, credit) => sum + credit.total_credits, 0);
  }

  /**
   * Check if user has active subscription
   * @param {Array} credits
   * @returns {boolean}
   */
  hasActiveSubscription(credits) {
    return credits.some(
      credit =>
        credit.package_type === 'subscription' &&
        (!credit.valid_until || new Date(credit.valid_until) > new Date())
    );
  }

  /**
   * Get current subscription
   * @param {Array} credits
   * @returns {Object|null}
   */
  getCurrentSubscription(credits) {
    const activeSubscription = credits.find(
      credit =>
        credit.package_type === 'subscription' &&
        (!credit.valid_until || new Date(credit.valid_until) > new Date())
    );
    return activeSubscription || null;
  }

  /**
   * Get credit history sorted by date
   * @param {Array} credits
   * @returns {Array}
   */
  getCreditHistory(credits) {
    return [...credits].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }

  /**
   * Subscribe to credit changes
   * @param {Function} callback
   * @returns {Promise<Object>} Subscription object
   */
  subscribeToCredits(callback) {
    console.log('Setting up credits subscription...');
    const channel = this.client.channel('credit_changes');

    const subscription = channel
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'credits'
        },
        payload => {
          console.log('Received credits change:', payload);
          // Fetch all credits to ensure we have the complete state
          this.fetchCredits().then(({data, error}) => {
            if (error) {
              console.error('Error fetching updated credits:', error);
              return;
            }
            callback(data);
          });
        }
      )
      .subscribe(status => {
        console.log('Credits subscription status:', status);

        if (status === 'SUBSCRIBED') {
          console.log('Successfully subscribed to credits changes');
        } else if (status === 'CLOSED') {
          console.log('Credits subscription closed');
        } else if (status === 'CHANNEL_ERROR') {
          console.error('Credits channel error:', status);
        }
      });

    return subscription;
  }

  /**
   * Unsubscribe from credit changes
   * @param {Object} subscription
   */
  async unsubscribeFromCredits(subscription) {
    if (subscription) {
      await subscription.unsubscribe();
    }
  }

  /**
   * Get product details for a credit package
   * @param {Object} credit
   * @returns {Object|null} Product details
   */
  getProductDetails(credit) {
    if (!credit.paddle_product_id) return null;

    // Map product IDs to their details
    const products = {
      pro_monthly: {
        name: 'Pro Monthly',
        description: 'Monthly subscription with 100 credits',
        period: 'month'
      },
      pro_yearly: {
        name: 'Pro Yearly',
        description: 'Annual subscription with 1200 credits',
        period: 'year'
      }
      // Add other product mappings as needed
    };

    return products[credit.paddle_product_id] || null;
  }

  /**
   * Get current subscription with product details
   * @param {Array} credits
   * @returns {Object|null}
   */
  getCurrentSubscriptionWithDetails(credits) {
    const subscription = this.getCurrentSubscription(credits);
    if (!subscription) return null;

    return {
      ...subscription,
      product: this.getProductDetails(subscription)
    };
  }
}
